import { render, staticRenderFns } from "./PostList.vue?vue&type=template&id=535600d9&scoped=true"
import script from "./PostList.vue?vue&type=script&lang=js"
export * from "./PostList.vue?vue&type=script&lang=js"
import style0 from "./PostList.vue?vue&type=style&index=0&id=535600d9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535600d9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerPostCategoryList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js').default,ControllerPostList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js').default})
