
import ControllerFooter from 'Controllers/ControllerFooter'
import ControllerNewsletter from 'Controllers/ControllerNewsletter'
import CustomForm from '@/components/ui/forms/CustomForm'
import FaIcons from '@/mixins/FaIcons'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTiktok,
  faTwitter,
  faWhatsapp,
  faTelegram,
  faViber,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(
  faInstagram,
  faFacebook,
  faYoutube,
  faTiktok,
  faXTwitter,
  faTwitter,
  faWhatsapp,
  faTelegram,
  faViber
)
// import PopUp from '@/components/ui/modals/PopUp.vue'
export default {
  components: {
    // PopUp,
    ControllerFooter,
    ControllerNewsletter,
    CustomForm,
    FontAwesomeIcon,
  },
  mixins: [FaIcons],
}
