import { render, staticRenderFns } from "./ProfileView.vue?vue&type=template&id=5bc30b4c&scoped=true"
import script from "./ProfileView.vue?vue&type=script&lang=js"
export * from "./ProfileView.vue?vue&type=script&lang=js"
import style0 from "./ProfileView.vue?vue&type=style&index=0&id=5bc30b4c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc30b4c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerAddress: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js').default,ControllerOrderHistory: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js').default,ControllerChangePassword: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js').default})
