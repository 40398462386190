
import ControllerPostList from 'Controllers/ControllerPostList'
import ControllerPostCategory from 'Controllers/ControllerPostCategory'
import ControllerPostCategoryList from 'Controllers/ControllerPostCategoryList'
import SocialShareModal from '@/components/ui/social/SocialShareModal'
import PostList from '@/components/cmsOverrides/PostList'

export default {
  components: {
    ControllerPostList,
    ControllerPostCategory,
    ControllerPostCategoryList,
    SocialShareModal,
  },
  extends: PostList,
}
