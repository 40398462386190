
import ControllerForgotPassword from 'Controllers/user/ControllerForgotPassword'
import ControllerResetPassword from 'Controllers/user/ControllerResetPassword'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import FaIcons from '@/mixins/FaIcons'
export default {
  components: {
    ControllerForgotPassword,
    ControllerResetPassword,
    MultipleBorderedButton,
  },
  mixins: [FaIcons],
  data() {
    return {
      passwordOptions: {
        newPasswordVisible: false,
        newPasswordRepeatedVisible: false,
      },
    }
  },
  methods: {
    handleSuccess() {
      this.$router.push(this.localePath(this.$Page.Login))
    },
    toggleNewPasswordVisibility() {
      this.passwordOptions.newPasswordVisible =
        !this.passwordOptions.newPasswordVisible
    },
    toggleNewPasswordRepeatedVisibility() {
      this.passwordOptions.newPasswordRepeatedVisible =
        !this.passwordOptions.newPasswordRepeatedVisible
    },
  },
}
