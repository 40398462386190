
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import ControllerAddress from 'Controllers/checkout/ControllerAddress'
import ControllerOrderHistory from 'Controllers/user/ControllerOrderHistory'
import ControllerChangePassword from 'Controllers/user/ControllerChangePassword'
import vClickOutside from 'v-click-outside'
import DateFormatter from '@/mixins/DateFormatter'
import FaIcons from '@/mixins/FaIcons'
export default {
  components: {
    MultipleBorderedButton,
    ControllerOrderHistory,
    ControllerChangePassword,
    ControllerAddress,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  mixins: [FaIcons, DateFormatter],

  data() {
    return {
      profileItems: null,
      clickedItem: null,
      passwordOptions: {
        oldPasswordVisible: false,
        newPasswordVisible: false,
        newPasswordRepeatedVisible: false,
      },
    }
  },

  watch: {
    clickedItem() {
      this.scrollToTop()
    },
  },

  created() {
    const items = {
      profileSettings: 1,
      orderHistory: 2,
      passwordSettings: 3,
    }
    Object.freeze(items)
    this.profileItems = items
  },
  methods: {
    toggleClickedItem(item) {
      if (this.clickedItem === item) {
        this.clickedItem = null
      } else {
        this.clickedItem = item
      }
    },
    unsetClickedItem() {
      this.clickedItem = null
    },
    onClickOutside() {
      this.unsetClickedItem()
    },
    toggleOldPasswordVisibility() {
      this.passwordOptions.oldPasswordVisible =
        !this.passwordOptions.oldPasswordVisible
    },
    toggleNewPasswordVisibility() {
      this.passwordOptions.newPasswordVisible =
        !this.passwordOptions.newPasswordVisible
    },
    toggleNewPasswordRepeatedVisibility() {
      this.passwordOptions.newPasswordRepeatedVisible =
        !this.passwordOptions.newPasswordRepeatedVisible
    },
    async handlePasswordChangeSuccess() {
      this.unsetClickedItem()
      this.$toast.success(this.$t('hsc.profile.passwordChangeSuccess'))
      await this.$auth.logout()
      this.$i18n.locale === 'en'
        ? this.$router.push('/login')
        : this.$router.push('/prijava')
    },
    showSuccessToast() {
      this.$toast.success(this.$t('hsc.notifications.saved'))
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
