
import ControllerHeader from 'Controllers/ControllerHeader'
import ControllerNavigationBar from 'Controllers/ControllerNavigationBar'
import ControllerNavigationItem from 'Controllers/ControllerNavigationItem'
import vClickOutside from 'v-click-outside'

import FaIcons from '@/mixins/FaIcons'
import debounce from 'lodash.debounce'

export default {
  components: {
    ControllerHeader,
    ControllerNavigationBar,
    ControllerNavigationItem,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  mixins: [FaIcons],

  data() {
    return {
      mobileMenuActive: false,
      activeNavigationItemId: null,
      count: 1,
    }
  },

  computed: {
    isLoginPage() {
      return this.$route.name?.split('_')[0] === this.$Page.Login
    },
    isHomePage() {
      return this.$route.path === '/' || this.$route.path === '/en'
    },
  },
  mounted() {
    if (process.browser) this.addEventListeners()
  },
  beforeDestroy() {
    if (process.browser) this.removeEventListeners()
  },
  methods: {
    closePanel() {
      if (this.$refs.header.activeItem !== null && this.count % 2 === 0) {
        this.$refs.header.activeItem = null
      }
      this.count++
    },
    addEventListeners() {
      window.addEventListener('scroll', this.emitScrollToAll)
      window.addEventListener('resize', this.emitResizeToAll)
    },
    removeEventListeners() {
      window.removeEventListener('scroll', this.emitScrollToAll)
      window.removeEventListener('resize', this.emitResizeToAll)
    },
    emitScrollToAll: debounce(function () {
      this.$nuxt.$emit('custom-scroll')
    }, 250),

    emitResizeToAll: debounce(function () {
      this.$nuxt.$emit('custom-resize')
    }, 250),

    handleLogoClick() {
      this.closeMobileMenu()
      if (this.isHomePage) {
        this.scrollToTop()
      }
    },

    toggleMobileMenu() {
      if (this.mobileMenuActive) this.closeMobileMenu()
      else this.openMobileMenu()
    },
    openMobileMenu() {
      this.mobileMenuActive = true
    },
    closeMobileMenu() {
      this.count = 1
      this.mobileMenuActive = false
      this.closeMobilePanel()
    },
    toggleMobilePanel(selectedItem) {
      if (
        this.activeNavigationItemId === selectedItem.id ||
        !selectedItem.children.length
      ) {
        this.closeMobilePanel()
      } else {
        this.openMobilePanel(selectedItem)
      }
    },
    openMobilePanel(selectedItem) {
      this.activeNavigationItemId = selectedItem.id
    },
    closeMobilePanel() {
      this.activeNavigationItemId = null
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    goToHomePage() {
      if (this.$i18n.locale === 'hr') {
        this.$router.push('/hr')
      } else {
        this.$router.push('/')
      }
    },
  },
}
