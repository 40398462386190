
import ControllerLogin from 'Controllers/user/ControllerLogin'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import FaIcons from '@/mixins/FaIcons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
library.add(faEye, faEyeSlash)
export default {
  components: {
    FontAwesomeIcon,
    ControllerLogin,
    MultipleBorderedButton,
  },
  mixins: [FaIcons],
  data() {
    return {
      passwordVisible: false,
    }
  },

  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible
    },
  },
}
