
import ControllerCart from 'Controllers/ControllerCart'
import CustomForm from '@/components/ui/forms/CustomForm'

export default {
  components: {
    ControllerCart,
    CustomForm,
  },
  props: {
    hideCode: {
      type: Boolean,
      default: false,
    },
  },
}
