import { render, staticRenderFns } from "./ErrorView.vue?vue&type=template&id=73af14f7&scoped=true"
import script from "./ErrorView.vue?vue&type=script&lang=js"
export * from "./ErrorView.vue?vue&type=script&lang=js"
import style0 from "./ErrorView.vue?vue&type=style&index=0&id=73af14f7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73af14f7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerError: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js').default})
