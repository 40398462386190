
import _ from 'lodash'
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
  // We cannot only listen for input event on input element because after clicking on buttons +/-, .stepUp() and stepDown() wont trigger it
  // That is why emitChange() has been called after calling .stepUp or stepDown()
  methods: {
    decrementValue: _.debounce(function () {
      this.$refs.customInput.stepDown()
      this.emitChange()
    }, 500),
    incrementValue: _.debounce(function () {
      this.$refs.customInput.stepUp()
      this.emitChange()
    }, 500),
    emitChange() {
      this.$emit('input', this.$refs.customInput.value)
    },
  },
}
