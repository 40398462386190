import { render, staticRenderFns } from "./CheckoutView.vue?vue&type=template&id=0f65a358&scoped=true"
import script from "./CheckoutView.vue?vue&type=script&lang=js"
export * from "./CheckoutView.vue?vue&type=script&lang=js"
import style0 from "./CheckoutView.vue?vue&type=style&index=0&id=0f65a358&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f65a358",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerAddress: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js').default,ControllerShipping: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js').default,ControllerPayment: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js').default})
