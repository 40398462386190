
import ControllerPostList from 'Controllers/ControllerPostList'
import ControllerPostCategoryList from 'Controllers/ControllerPostCategoryList'
import SocialShareModal from '@/components/ui/social/SocialShareModal'
import FaIcons from '@/mixins/FaIcons'
import Pagination from '@/components/ui/pagination/Pagination'

export default {
  components: {
    ControllerPostList,
    ControllerPostCategoryList,
    SocialShareModal,
    Pagination,
  },
  mixins: [FaIcons],
  data() {
    return {
      socialShareModalActive: false,
      postToShare: null,
    }
  },
  methods: {
    toggleSocialShare(post) {
      this.socialShareModalActive
        ? this.closeSocialShare()
        : this.openSocialShare(post)
    },
    openSocialShare(post) {
      this.postToShare = post
      this.socialShareModalActive = true
    },
    closeSocialShare() {
      this.postToShare = null
      this.socialShareModalActive = false
    },
  },
}
