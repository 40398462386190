
import ControllerAddress from 'Controllers/checkout/ControllerAddress'
import ControllerShipping from 'Controllers/checkout/ControllerShipping'
import ControllerPayment from 'Controllers/checkout/ControllerPayment'
import OrderReview from '@/components/ui/forms/OrderReview'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import { mapActions } from 'vuex'

export default {
  components: {
    ControllerAddress,
    ControllerShipping,
    ControllerPayment,
    OrderReview,
    MultipleBorderedButton,
  },
  data() {
    return {
      hideCode: false,
      r1: false,
    }
  },
  computed: {
    currentLanguage() {
      return this.$i18n.localeProperties.iso
    },
  },

  async mounted() {
    await this.$refs.controllerAddress.fetchUserData()
    await this.$nextTick()
    // if (this.currentLanguage === 'en') {
    //   this.$set(this.$refs.controllerAddress.form.deliveryForm, 'country', 'US')
    //   this.$set(this.$refs.controllerAddress.form.billingForm, 'country', 'US')
    // } else {
    //   this.$set(this.$refs.controllerAddress.form.deliveryForm, 'country', 'HR')
    //   this.$set(this.$refs.controllerAddress.form.billingForm, 'country', 'HR')
    // }

    this.$watch(
      () => {
        return this.$parent.stepState.activeStep
      },
      (v) => {
        this.hideCode = v !== 'address'
      }
    )
  },
  // test
  methods: {
    ...mapActions(['addCartItems']),
    goToPart(part) {
      setTimeout(() => {
        if (!part || !part.length) return
        if (document) {
          const el = document.getElementById(`${part}`)
          if (!el) return
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        }
      }, 1000)
    },
    setPurchasedItems() {
      if (this.$cart && this.$cart.storage && this.$cart.storage.cart) {
        this.addCartItems(this.$cart.storage.cart)
      }
    },
    selectedMethodValid(shippingMethods, methodId, branchId) {
      if (!methodId) return false
      const selectedMethod = shippingMethods.find(
        (method) => method.id === methodId
      )
      // Somehow method was not found
      if (!selectedMethod) return false
      // Selected method does not include branches
      else if (selectedMethod && !selectedMethod.options) return true
      // Selected method includes branches, but no branch is selected
      else if (!branchId) return false
      // Selected method includes branches and branch is selected
      return true
    },
  },
}
