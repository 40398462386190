
export default {
  props: {
    submitMethod: {
      type: Function,
      default() {
        return null
      },
    },
    value: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    inputBgColor: {
      type: String,
      default: 'white',
    },
    growAfterSmallScreen: {
      type: Boolean,
      default: false,
    },
    maxInputWidthClass: {
      type: String,
      default: '',
    },
    applyBorders: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
}
