import { render, staticRenderFns } from "./ForgotPasswordView.vue?vue&type=template&id=5fecfe15"
import script from "./ForgotPasswordView.vue?vue&type=script&lang=js"
export * from "./ForgotPasswordView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerForgotPassword: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js').default,ControllerResetPassword: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js').default})
