
import FaIcons from '@/mixins/FaIcons'
import vClickOutside from 'v-click-outside'
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [FaIcons],
  props: {
    endpoint: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    quote: {
      type: String,
      default: '',
    },
    hashtags: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      baseUrl: window.location.origin,
      networks: [
        {
          slug: 'facebook',
          name: 'Facebook',
          color: '#1877f2',
        },
        {
          slug: 'reddit',
          name: 'Reddit',
          color: '#ff4500',
        },
        {
          slug: 'skype',
          name: 'Skype',
          color: '#00aff0',
        },
        {
          slug: 'twitter',
          name: 'Twitter',
          color: '#1da1f2',
        },
        {
          slug: 'messenger',
          name: 'Messenger',
          color: '#0084ff',
        },

        {
          slug: 'whatsapp',
          name: 'Whatsapp',
          color: '#25d366',
        },
        {
          slug: 'sms',
          name: 'SMS',
          color: '#333333',
        },
        {
          slug: 'email',
          name: 'Email',
          color: '#333333',
        },
      ],
    }
  },
  methods: {
    onClickOutside() {
      this.$emit('closeSocialShare')
    },
  },
}
