
export default {
  name: 'MultipleBorderedButton',
  // Use custom color names from tailwind.config.js to set primaryColorName prop
  // if you want to insert new color:
  // First add it to tailwind.config.js and create 'colorname.50' color version with opacity of 0.5 which will be used for outter border,
  // then use its 100 opacity name as a prop

  props: {
    primaryColorName: {
      type: String,
      default: 'pureYellow',
    },
    textColorName: {
      type: String,
      default: 'darkGrayishViolet',
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isHovered: false,
    }
  },

  watch: {
    disabled() {
      this.hideBorders()
    },
  },
  methods: {
    showBorders() {
      if (this.disabled) {
        return
      }
      this.isHovered = true
    },
    hideBorders() {
      this.isHovered = false
    },
  },
}
