
import ControllerCart from 'Controllers/ControllerCart'
import CustomNumberInput from '@/components/ui/inputs/CustomNumberInput'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import CustomForm from '@/components/ui/forms/CustomForm'
import FaIcons from '@/mixins/FaIcons'
export default {
  components: {
    ControllerCart,
    CustomNumberInput,
    MultipleBorderedButton,
    CustomForm,
  },
  mixins: [FaIcons],
}
