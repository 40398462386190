
import FaIcons from '@/mixins/FaIcons'
import ControllerError from 'Controllers/ControllerError'
export default {
  components: {
    ControllerError,
  },
  mixins: [FaIcons],

  // test pipeline
}
