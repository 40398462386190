
import FaIcons from '@/mixins/FaIcons'
export default {
  mixins: [FaIcons],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default() {
        return {
          page: 1,
        }
      },
    },
    paginationState: {
      type: Object,
      default() {
        return {
          totalPages: 1,
          totalItems: 1,
          onFirstPage: true,
          onLastPage: true,
        }
      },
    },
    nextPage: {
      type: Function,
      default() {
        return null
      },
    },
    previousPage: {
      type: Function,
      default() {
        return null
      },
    },
    firstPage: {
      type: Function,
      default() {
        return null
      },
    },
    lastPage: {
      type: Function,
      default() {
        return null
      },
    },
  },
  computed: {
    leftCellNumber() {
      // Nisi ni na prvom ni na zadnjem pageu
      if (
        !this.paginationState.onFirstPage &&
        !this.paginationState.onLastPage
      ) {
        return this.options.page - 1
        // Na prvom pageu si i nije bitno jesi na zadnjem
      } else if (this.paginationState.onFirstPage) {
        return this.options.page
        // Nisi na prvom pageu, ali jesi na zadnjem i ima ih ukupno manje od 3 (ima ih 2)
      } else if (this.paginationState.totalPages < 3) {
        return this.options.page - 1
        // Nisi na prvom pageu, ali jesi na zadnjem i ima ih ukupno vise ili jednako 3
      } else {
        return this.paginationState.totalPages - 2
      }
    },
    middleCellNumber() {
      // Ima manje od 2 pagea
      if (this.paginationState.totalPages < 2) {
        return false
        // Ima vise ili jednako 2 pagea i nisi ni na prvom ni na zadnjem
      } else if (
        !this.paginationState.onFirstPage &&
        !this.paginationState.onLastPage
      ) {
        return this.options.page
        // Ima vise ili jednako 2 pagea i na prvom si
      } else if (this.paginationState.onFirstPage) {
        return this.options.page + 1
        // Ima vise ili jednako 2 pagea i na zadnjem si
      } else {
        // Ima 2 pagea i na zadnjem si
        if (this.paginationState.totalPages === 2) {
          return this.options.page
        }
        // Ima vise od 2 pagea i na zadnjem si
        return this.paginationState.totalPages - 1
      }
    },
    rightCellNumber() {
      // Ima manje od 3 pagea
      if (this.paginationState.totalPages < 3) {
        return false
      } else if (
        // Ima vise ili jednako 3 pagea i nisi ni na prvom ni na zadnjem
        !this.paginationState.onFirstPage &&
        !this.paginationState.onLastPage
      ) {
        return this.options.page + 1
        // Ima vise ili jednako 3 pagea i na prvom si
      } else if (this.paginationState.onFirstPage) {
        return this.options.page + 2
      } else {
        // Ima vise ili jednako 3 pagea i na zadnjem si
        return this.paginationState.totalPages
      }
    },
  },
  watch: {
    'options.page'() {
      this.scrollToTop()
    },
  },
  methods: {
    emitPageClick(pageNumber) {
      if (pageNumber === this.options.page) return
      this.$emit('pageNumberClicked', pageNumber)
    },
    handlePageClick(clickedPage) {
      this.options.page = clickedPage
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
