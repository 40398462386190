
import ControllerRegister from 'Controllers/user/ControllerRegister'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import FaIcons from '@/mixins/FaIcons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
library.add(faEye, faEyeSlash)
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FontAwesomeIcon,
    ControllerRegister,
    MultipleBorderedButton,
  },
  mixins: [FaIcons],
  data() {
    return {
      confirmedPassword: '',
      passwordOptions: {
        passwordVisible: false,
        passwordRepeatedVisible: false,
      },
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordOptions.passwordVisible =
        !this.passwordOptions.passwordVisible
    },
    togglePasswordRepeatedVisibility() {
      this.passwordOptions.passwordRepeatedVisible =
        !this.passwordOptions.passwordRepeatedVisible
    },
  },
}
