
import ControllerPost from 'Controllers/ControllerPost'
import FaIcons from '@/mixins/FaIcons'
import DateFormatter from '@/mixins/DateFormatter'
import SocialShareModal from '@/components/ui/social/SocialShareModal'
export default {
  components: {
    ControllerPost,
    SocialShareModal,
  },
  mixins: [FaIcons, DateFormatter],
  data() {
    return {
      shareModalActive: false,
    }
  },
  mounted() {
    this.$nuxt.$on('custom-scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.$nuxt.$off('custom-scroll')
  },

  methods: {
    getCategoryString(categories) {
      let stringBuilder = ''
      if (!categories) return
      if (!categories || !categories.length) return
      categories.forEach((category, index) => {
        stringBuilder += category.title
        if (index <= categories.length - 3) {
          stringBuilder += ', '
        } else if (index === categories.length - 2) {
          stringBuilder += ' & '
        }
      })
      return stringBuilder
    },
    toggleSocialShare() {
      this.shareModalActive = !this.shareModalActive
    },
    closeSocialShare() {
      this.shareModalActive = false
    },
    handleScroll() {
      // If browser does not support pageYOffset
      if (!window.pageYOffset && window.pageYOffset !== 0) return
      const arrowIcon = document.getElementById('down-arrow-icon')
      if (window.pageYOffset < window.innerHeight / 1.75) {
        if (!arrowIcon.classList.contains('bouncing-arrow')) {
          arrowIcon.classList.add('bouncing-arrow')
        }
      } else {
        arrowIcon.classList.remove('bouncing-arrow')
      }
    },
  },
}
